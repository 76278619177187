const CONST = {
    CATEGORY_CHUNK_SIZE: 5,
    AD_REFRESH_TIME: 120000,
    SCRIPT_LOADING_TIME_WAIT: 1200,
    USER_STORAGE_KEY: 'ag_user',
    PAGE_VISITED: 'ag_page_visited',
    WEBSITE_ABBREVIATION: 'AT'
}

Object.freeze(CONST);

export default CONST;
